import React from 'react';

function Discord() {
  return (
    <div>
      <h2>Discord</h2>
      <p>Hier ist die Discord-Seite.</p>
    </div>
  );
}

export default Discord;
