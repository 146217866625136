import React from 'react';
import { Link } from 'react-router-dom';
import './IconLink.css';

function IconLink({ icon, url }) {
  return (
    <Link to={url} className="icon-link">
      <img src={icon} alt="icon" className="icon-image" />
    </Link>
  );
}

export default IconLink;
