import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './content/img/minecraftserver-logo-100.png';
import discord from './content/img/discord-logo-100.png';
import uptime from './content/img/uptime-logo-100.png';
import map from './content/img/map-logo-100.png';
import './App.css';
import IconLink from './components/IconLink';

import Home from './pages/Home';
import Uptime from './pages/Uptime';
import Discord from './pages/Discord';
import Map from './pages/Map';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Link to="/">
            <img src={logo} className="Server-logo" alt="logo" />
          </Link>
          <h1 className='Header-title'>Tesh's Minecraft Server</h1>
          <div className="icon-links">
            <IconLink icon={discord} url="/discord" />
            <IconLink icon={uptime} url="/uptime" />
            <IconLink icon={map} url="/map" />
          </div>
        </header>
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/uptime" element={<Uptime />} />
            <Route path="/discord" element={<Discord />} />
            <Route path="/map" element={<Map />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
