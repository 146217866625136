import React from 'react';
import './Home.css'; 

function Home() {
  return (
<div className='Home-Container'>
    <div className='Start-Text'>
    <h1>Willkommen auf dem Tesh Minecraft Server</h1>
    <p><strong>Klein aber fein</strong> – das ist der Tesh Minecraft Server. </p>
    <p> Nach zahlreichen Minecraft-Phasen und wechselnden Server-Abos bleibt dieser Server als langfristiges Projekt bestehen. Hier kannst du rund um die Uhr Minecraft spielen, falls du mal eine Pause von Riot Games, Shootern oder Fortnite brauchst.</p>
  </div>

    <div className='Grid-Container'>
    <div className='Grid-Item'>
<h2>Aktuelle Spielinformationen:</h2>
<ul>
  <li><strong>Minecraft-Version:</strong> 1.20.1</li>
  <li><strong>Modifikationen:</strong> Forge</li>
  <li><strong>Modpack:</strong> Minecolonies</li>
  <li><strong>Schwierigkeit:</strong> Normal</li>
  <li><strong>Weltziel:</strong> </li>
</ul>
</div>

    <div className='Grid-Item'>
      <h2>Installation</h2>
      <ol>
        <li>Lade CurseForge herunter</li>
        <li>Richte Forge ein</li>
        <li>Lade das Modpack herunter</li>
        <li>Nimm nachträgliche Änderungen im Pack vor</li>
        <li>Verbinde dich im Multiplayer (IP bekannt/beantragen)</li>
        <li>Nutze DynMap</li>
      </ol>
    </div>
    
    <div className='Grid-Item'>
      <h2>Map</h2>
      <p>Unsere Mod namens <strong>DynamicMap</strong> ist auf dem Server verfügbar und zeigt aktuelle Ereignisse in Echtzeit an. Diese dynamische Karte ist eine großartige Möglichkeit, um den Überblick über die Spielwelt zu behalten und jederzeit zu sehen, was gerade passiert. Du kannst sie nutzen, um Spielerpositionen zu verfolgen, Gebiete zu erkunden und strategisch zu planen. Weitere Informationen zur Installation dieser Map findest du im Installationsbereich unserer Website. Wir stellen dir Schritt-für-Schritt-Anleitungen und hilfreiche Tipps zur Verfügung, damit du die DynamicMap problemlos nutzen kannst.</p>
    </div>

    <div className='Grid-Item'>
      <h2>Idee</h2>
      <p>Unser Server bietet einer kleinen Gruppe von Spielern eine Plattform, auf der sie ihre Freizeit verbringen können. Egal, ob sie sich kreativ austoben, eine neue Mod erkunden oder einfach den Kopf freibekommen möchten – unser Server bietet die ideale Umgebung dafür. Wir möchten eine Community schaffen, in der sich jeder willkommen fühlt und gemeinsam spannende Projekte entstehen können. Mit über 400 verschiedenen Mods sind der kreativen Gestaltung keine Grenzen gesetzt. Von neuen Biomen und Kreaturen bis hin zu technischen Erweiterungen und Magie – die Vielfalt sorgt für ein langanhaltendes und freudiges Spielerlebnis. Zudem bieten wir regelmäßige Updates und neue Inhalte, um die Spielerfahrung stets frisch und aufregend zu halten.</p>
    </div>

    <div className='Grid-Item'>
      <h2>Events</h2>
      <p>Von Zeit zu Zeit organisieren wir spannende Spiele-Events, die für zusätzliche Abwechslung und Herausforderungen sorgen. Eines der Highlights ist der erste Kampf gegen den Enderdrachen. Dieses epische Event wird im Voraus geplant und mit den zu diesem Zeitpunkt verfügbaren Mitspielern abgestimmt. Es erfordert Teamwork, Strategie und Mut, um den Enderdrachen zu besiegen. Alle Details dazu werden rechtzeitig über unsere Website oder Nachrichtendienste kommuniziert, sodass jeder die Möglichkeit hat, sich vorzubereiten und teilzunehmen. Neben dem Enderdrachen-Event gibt es auch kleinere Wettbewerbe, Bau-Challenges und Gemeinschaftsprojekte, bei denen die Spieler zusammenarbeiten und ihre Kreativität unter Beweis stellen können. Unsere Events sind darauf ausgelegt, die Community zu stärken und unvergessliche Spielerlebnisse zu schaffen.</p>
    </div>


    <div className='Grid-Item'>
      <h2>Informationen</h2>
      <p><strong>Gibt es die Simple Voice Chat Mod?</strong><br />Nein, aus technischen Gründen nicht, aber wir haben einen Discord-Server.</p>
      <p><strong>Ist der Server 24/7 an?</strong><br />Jein. Der Server läuft die meiste Zeit, kann aber aufgrund technischer Probleme gelegentlich offline sein.</p>
    </div>
    <div className='Grid-Item'>
      <h2>Mitspielen</h2>
      <h3>Wer kann mitspielen?</h3>
      <p>Grundsätzlich kann jeder mitspielen. Aufgrund begrenzter Serverressourcen gibt es jedoch bestimmte Kriterien zur bevorzugten Aufnahme von Mitspielern.</p>
      <h3>Was brauche ich?</h3>
      <ul>
        <li>Minecraft Java Edition</li>
        <li>Mindestens 6 GB freien RAM</li>
        <li>Eine E-Mail-Adresse</li>
      </ul>
      <h3>Wo finde ich die Server-IP?</h3>
      <p>Aus Sicherheitsgründen wird die Server-IP nicht öffentlich bekanntgegeben. Entweder hast du sie bereits erhalten oder du musst sie beantragen. Weitere Informationen dazu findest du im Installationsbereich.</p>
    </div>

    </div>
<div className='Start-Text'>
      <h1>Regeln</h1>
      <h2>Allgemeine Verhaltensregeln:</h2>
      <ul>
        <li><strong>Respekt und Höflichkeit:</strong>
          <ul>
            <li>Behandle alle Spieler mit Respekt und Freundlichkeit.</li>
            <li>Keine Beleidigungen, Belästigungen oder diskriminierendes Verhalten.</li>
            <li>Die Server-IP darf nicht ohne Erlaubnis weitergegeben werden.</li>
          </ul>
        </li>
        <li><strong>Kein Griefing:</strong> Das absichtliche Zerstören oder Verändern von Bauwerken anderer Spieler ist verboten.</li>
        <li><strong>Keine Hacks oder Cheats:</strong>
          <ul>
            <li>Das Verwenden von Modifikationen, Hacks oder Cheats, die unfaire Vorteile verschaffen, ist verboten.</li>
            <li>Bauten, Blöcke oder Items, die Lags oder Crashes verursachen können, sind nicht erlaubt.</li>
          </ul>
        </li>
        <li><strong>Fair Play:</strong> Halte dich an die Serverregeln und nutze keine Exploits oder Bugs aus.</li>
        <li><strong>Sprache und Kommunikation:</strong>
          <ul>
            <li>Verwende angemessene und verständliche Sprache im Chat.</li>
            <li>Spam und unnötige Wiederholungen sind nicht erlaubt.</li>
          </ul>
        </li>
        <li><strong>Serverregeln beachten:</strong> Folge den Anweisungen der Server-Administratoren und Moderatoren.</li>
      </ul>
    </div>
    <div className='Start-Text'>
    <h2>In-Game Gameplay-Regeln:</h2>
      <ul>
        <li><strong>Teams und Allianzen:</strong>
          <ul>
            <li>Spieler dürfen Teams bilden und sich miteinander verbünden.</li>
            <li>Teammitglieder müssen sich gegenseitig unterstützen und respektieren.</li>
          </ul>
        </li>
        <li><strong>Gebiets-Claiming:</strong>
          <ul>
            <li>Spieler dürfen Gebiete claimen, um ihre Bauwerke und Ressourcen zu schützen.</li>
            <li>Claimed keine Gebiete direkt an den Grenzen anderer Spieler, um Konflikte zu vermeiden.</li>
            <li>Respektiere die Claims anderer Spieler und betritt sie nur mit Erlaubnis.</li>
          </ul>
        </li>
        <li><strong>Bauen und Ressourcenabbau:</strong>
          <ul>
            <li>Große Landschaftsveränderungen sollten vermieden werden, um die Welt nicht unästhetisch zu gestalten.</li>
            <li>Abgebaute Ressourcen sollten in Maßen und mit Rücksicht auf die Umgebung gesammelt werden.</li>
          </ul>
        </li>
        <li><strong>PvP (Player vs. Player):</strong>
          <ul>
            <li>PvP ist nur in bestimmten, dafür vorgesehenen Bereichen oder nach beidseitigem Einverständnis erlaubt.</li>
            <li>Hinterhältige Angriffe und das Töten von Spielern, die nicht einverstanden sind, ist verboten.</li>
          </ul>
        </li>
        <li><strong>Gemeinschaftliche Projekte:</strong>
          <ul>
            <li>Spieler werden ermutigt, an gemeinschaftlichen Projekten teilzunehmen und zusammenzuarbeiten.</li>
            <li>Respektiere die Beiträge und Arbeit anderer Spieler an solchen Projekten.</li>
          </ul>
        </li>
      </ul>
    </div>
</div>
  );
}
export default Home;