import React from 'react';

function Uptime() {
  return (
    <div>
      <h2>Uptime</h2>
      <p>Hier ist die Uptime-Seite.</p>
    </div>
  );
}

export default Uptime;
